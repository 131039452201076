import { createStore } from 'redux';
import rootReducer from './reducers';
import { EmployeeMeta } from './reducers/user';

export interface ReduxStateI {
  user: EmployeeMeta;
}

const store = createStore(rootReducer);

export default store;
