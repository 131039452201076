import React from 'react';
import { Wrapper } from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader: React.FC = () => (
  <Wrapper>
    <CircularProgress />
  </Wrapper>
);

export default Loader;
