import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import api from 'api';
import { actionsCreator } from 'redux/reducers/user';
import Loader from 'components/SuspenseLoader';
import SnackBar from 'components/SnackBar';
import CustomError from 'pages/error/custom';
import { ReduxStateI } from 'redux/store';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import { GlobalStyle } from 'styles/globalStyles';
import { theme } from 'styles/themeConfig';
import { Provider } from 'react-redux';
import store from 'redux/store';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pl';
import 'styles/normalize.css';
import 'typeface-roboto';
import * as Yup from 'yup';
// @ts-ignore
import ScrollMemory from 'react-router-scroll-memory';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LoggedView: any = React.lazy(() => import('pages/root/loggedView'));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NoLoggedView: any = React.lazy(() => import('pages/root/noLoggedView'));

export const browseHistory = createBrowserHistory();

Yup.setLocale({
  mixed: {
    notType: _ref => {
      switch (_ref.type) {
        case 'number':
          return 'Wartość powinna być liczbą.';
        case 'string':
          return 'Wartość powinna być tekstem';
        case 'date':
          return 'Wartość powinna być datą';
        default:
          return 'Niepoprawny typ wartości';
      }
    },
  },
});

const View: React.FC = () => {
  const [logged, setLogged] = React.useState('init');
  const uuid = useSelector((state: ReduxStateI) => state.user.uuid);
  const dispatch = useDispatch();

  if (uuid === undefined && logged === 'init') {
    setLogged('checking');
    axios
      .get(api.checkAuth, { withCredentials: true })
      .then(response => {
        dispatch(actionsCreator.addUserMeta(response.data));
        setLogged('authorized');
      })
      .catch(e => {
        if (e.message === 'Network Error') {
          return setLogged('network_error');
        }
        setLogged('unauthorized');
      });
  }

  if (logged === 'network_error') {
    return (
      <>
        <CustomError
          title={'Serwer nie odpowiada'}
          message={'Brak połączenia z serwerem. Sprawdź swoje połączenie z internetem.'}
        />
      </>
    );
  }

  if (logged === 'authorized' || uuid !== undefined) {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={'pl'}>
        <SnackBar
          message={'Zalogowano do systemu.'}
          variant={'success'}
          origin={['bottom', 'left']}
          timeout={3000}
        />
        <LoggedView />
      </MuiPickersUtilsProvider>
    );
  }

  if (logged === 'unauthorized') {
    return <NoLoggedView />;
  }

  return <Loader />;
};

const RootWrapper: React.FC = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router history={browseHistory}>
        <ScrollMemory elementID={'main-content'} />
        <GlobalStyle />
        <React.Suspense fallback={<Loader />}>
          <View />
        </React.Suspense>
      </Router>
    </ThemeProvider>
  </Provider>
);

export default RootWrapper;
