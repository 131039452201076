export interface EmployeeMeta {
  name: string;
  surname: string;
  uuid: string;
  permission: number;
}

const initialState = {
  user: {
    name: '',
    surname: '',
    uuid: '',
    permission: 0,
  } as EmployeeMeta,
};

export const actions = {
  ADD_USER_META: 'ADD_USER_META',
};

export const actionsCreator = {
  addUserMeta: (meta: EmployeeMeta) => ({
    type: actions.ADD_USER_META,
    meta,
  }),
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actions.ADD_USER_META:
      return action.meta;
    default:
      return state;
  }
};

export default reducer;
