import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

export const Wrapper = styled(Card)`
  padding: 20px 25px;
  align-self: center;
  margin-top: auto;
`;

export const Heading = styled(Typography)`
  margin-bottom: 10px !important;
`;
