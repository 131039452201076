import ApolloClient, { gql, defaultDataIdFromObject } from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';

const apiLink =
  process.env.NODE_ENV === 'development'
    ? 'https://localhost:3001'
    : 'https://stekra-erp.usermd.net';

export const fragments = {
  projectTable: gql`
    fragment ProjectTable on Project {
      __typename
      name
      dateStart
      dateEnd
      status
      label
      labelColor
      id
      forClient {
        uuid
        name
      }
      accessEmployee {
        uuid
        name
        surname
      }
    }
  `,
};

export const uri = {
  authIn: apiLink + '/api/auth/login',
  authOut: apiLink + '/api/auth/logout',
  checkAuth: apiLink + '/api/auth/check',
  graphQl: apiLink + '/api/v1',
  avatar: apiLink + '/api/static/avatar/',
  uploadProjectFiles: apiLink + '/api/static/upload/',
  uploadAvatar: apiLink + '/api/static/uploadavatar/',
};

export const getFile = (path: string) => {
  return apiLink + `/api/static/files/` + path;
};

export const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: true,
    dataIdFromObject: object => {
      if (object.hasOwnProperty('id')) return object.id;
      if (object.hasOwnProperty('uuid')) {
        // @ts-ignore
        return object.uuid;
      }
      return defaultDataIdFromObject(object);
    },
  }),
  uri: uri.graphQl,
  credentials: 'include',
  onError: ({ networkError }) => {
    if (networkError && networkError.message === 'Failed to fetch') {
      networkError.message = 'Brak połączenia z serwerem. Sprawdź swoje połączenie z internetem.';
    }
    // @ts-ignore
    if (networkError && networkError.statusCode === 401) {
      networkError.message =
        'Nieautoryzowany dostęp do zasobów. Twoja sesja mogła wygasnąć lub nie masz dostępu do tych zasobów.';
    }
  },
});

export default uri;
