import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { amber, green } from '@material-ui/core/colors';
import clsx from 'clsx';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface SnackProps {
  message: string;
  origin?: ['top' | 'bottom', 'left' | 'right' | 'center'];
  variant: 'success' | 'warning' | 'error' | 'info';
  onClose?: Function;
  timeout?: number;
}

const SnackBar: React.FC<SnackProps> = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const Icon = variantIcon[props.variant];

  function handleClose() {
    setOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: props.origin ? props.origin[0] : 'top',
        horizontal: props.origin ? props.origin[1] : 'center',
      }}
      open={open}
      autoHideDuration={props.timeout || 4000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classes[props.variant]}
        aria-describedby='notif'
        message={
          <span className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {props.message}
          </span>
        }
        action={[
          <IconButton key='close' aria-label='Close' color='inherit' onClick={handleClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export const asFunction = (props: SnackProps) => <SnackBar {...props} />;

export default SnackBar;
