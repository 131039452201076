import React from 'react';
import { Wrapper, Heading } from 'pages/error/styles';
import Typography from '@material-ui/core/Typography';
import { Button, Grid } from '@material-ui/core';

interface CustomErrorProps {
  title: string;
  message: string;
  tryAgain?: Function;
}

const Error: React.FC<CustomErrorProps> = ({ title, message, tryAgain }) => {
  function handleClick() {
    if (tryAgain) tryAgain();
  }

  return (
    <Wrapper>
      <Grid component={'div'} container justify={'flex-end'}>
        <Grid item xs={12} component={'div'}>
          <Heading variant={'h4'}>{title}</Heading>
          <Typography component={'p'}>
            {message}
            <br />
            Jeśli błąd się powtarza, prosimy o kontakt z Administratorem systemu.
          </Typography>
        </Grid>
        {tryAgain && (
          <Grid item component={'div'}>
            <Button onClick={handleClick} variant={'contained'} color={'primary'}>
              Spróbuj ponownie
            </Button>
          </Grid>
        )}
      </Grid>
    </Wrapper>
  );
};

export default Error;
