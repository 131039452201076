import { createGlobalStyle } from 'styled-components';

/**
 * Global styles for ERP System.
 */
export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
  }
  
  html {
    font-size: 16px;
  }
  
  body {
    font-size: 1rem;
    background-color: white;
  }
`;
